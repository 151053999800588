import * as React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import "./layout.css"
import ScrollAnimation from 'react-animate-on-scroll';
import { Element } from 'react-scroll';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function AboutMe(){

  // if(process.env.GATSBY_MEMBER_AREA === "member_pass_live"){
  //   console.log('Yes');
  // }
  // else{
  //   console.log("No")
  // }

  return (
    <>      
    <Element name="aboutme-section">
    <section className="about-me">
        <ScrollAnimation animateIn="fadeIn">
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h2">About</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="body1" className="intro-para">
                    I'm Houston, a highly experienced full stack developer, working within Belfast, or remotely from sunny Ballymena, County Antrim, Northern Ireland.
                </Typography>
                <Typography variant="body1">
                    I've had the pleasure with working with a wide range of companies and clients across various sectors, including higher education institutions, professional roles and a number of startups.  
                </Typography>
                <Typography variant="body1">
                  As an innovative and passionate front-end developer with over 15 years experience, I consistently deliver high-quality web solutions to every project.
                </Typography>
                {/* {process.env.GATSBY_MEMBER_AREA}  */}
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="helpWrapper">
              <Typography variant="h4">
                  I can help with:
              </Typography>
              <Typography variant="body1">
                  Front/Backend Development
              </Typography>
              <Typography variant="body1">
                  JAMStack Development
              </Typography>
              <Typography variant="body1">
                  Bespoke CMS builds. (WordPress, Statamic etc)
              </Typography>
              <Typography variant="body1">
                  eCommerce stores. (Shopify, WooCommerce etc)
              </Typography>
              <Typography variant="body1">
                  Laravel / Wordpress Applications
              </Typography>
              <Typography variant="body1">
                  UI/UX Design
              </Typography>
              <Typography variant="body1">
                  and more...
              </Typography>
              </Grid>
            </Grid>
          </Container>
        </ScrollAnimation>
    </section>
    </Element>
    </>
  );
};