import React from "react";
import Layout from "../components/Layout";
import HeaderShowreel from "../components/HeaderShowreel";
import MyWork from "../components/MyWork";
import AboutMe from "../components/AboutMe";
import Footer from "../components/Footer";

const IndexPage = () => {
 
  return (
    <Layout>
      <HeaderShowreel />
      <MyWork />
      <AboutMe />
      <Footer /> 
    </Layout>
  );
};


export default IndexPage;