import React from "react";
import "./layout.css"
import { graphql, useStaticQuery } from 'gatsby';
import { Container, Grid, Typography, Box, Button } from '@material-ui/core';
import "./layout.css";
import "./custom.css"
import { Element } from 'react-scroll';
import FixedNavBar from "./FixedNavbar";
import BackgroundImage from 'gatsby-background-image';

export default function FullWidthImage() {

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "hr_me.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
   const imageData = data.desktop.childImageSharp.fluid
  
  return (
    <React.Fragment>
      <Element name="header">
        <div className="header">
        <BackgroundImage
          Tag="div"
          className="background"
          fluid={imageData}
        >
          <div className="backgroundShadow">
            <FixedNavBar />
            <Container className="header-content">
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Box className="content-wrapper"> 
                    <Typography variant="h1">
                      Full Stack<br/>Developer
                    </Typography>
                    <Typography variant="body1">
                      I create and build user friendly websites and applications that help businesses and start-ups grow to the next level.
                    </Typography>
                    <a href="mailto:hi@houstonreid.com?subject=Hello there">
                      <Button className="button">
                        Let's Start a Project
                      </Button>
                    </a>
                  </Box>    
                 </Grid>           
                </Grid>
              </Container>
            </div>
          </BackgroundImage>          
        </div>
      </Element>
    </React.Fragment>
  );
}