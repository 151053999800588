import * as React from "react";
import { Container, Grid, Typography, Box, Chip} from "@material-ui/core";
import "./layout.css"
import ScrollAnimation from 'react-animate-on-scroll';
import { Element } from 'react-scroll';
import { StaticImage } from "gatsby-plugin-image"

export default function MyWork(){

  return (
    <>
    <Element name="mywork-section">
    <section className="my-work">
        <ScrollAnimation animateIn="fadeIn">
          
          <Container maxWidth="lg" spacing={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} className="text-center">
                <Typography variant="h2">Latest Work</Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="mywork-wrapper ">
                  <div className="default">
                    <StaticImage src="../img/hscd-work-hover.jpg" alt="High Sheriff of County Down"/>
                  </div>
                  {/* <div className="hover">
                    <StaticImage src="../img/hscd-work-alt.jpg" alt="High Sheriff of County Down"/>
                  </div> */}
                </div>
                <Box className="mywork-example">
                  <Box>
                    <Typography variant="h3">High Sheriff of County Down</Typography>
                    <Typography variant="body1">New Jamstack site designed and developed with Gatsby and Netlify CMS.</Typography>
                    <Chip color="primary" size="small" label="Jamstack" style={{marginRight:"5px", fontSize:"0.7rem"}}/>
                    <Chip color="primary" size="small" label="Gatsby" style={{marginRight:"5px", fontSize:"0.7rem"}} />
                    <Chip color="primary" size="small" label="UX/UI Design" style={{marginRight:"5px", fontSize:"0.7rem"}}/>
                    <Chip color="primary" size="small" label="Netlify CMS" style={{marginRight:"5px", fontSize:"0.7rem"}}/>
                  </Box>
                </Box>
              </Grid>

              
              <Grid item xs={12} sm={12} md={6}>
                <div className="mywork-wrapper nudge">
                  <div className="default">
                    <StaticImage src="../img/dblgt-work-hover.jpg" alt="Danny Boy Links Golf Tours"/>
                  </div>
                  {/* <div className="hover">
                    <StaticImage src="../img/gt-work.jpg" alt="Grass Technology Ltd"/>
                  </div> */}
                </div>
                <Box className="mywork-example">
                  <Box>
                    <Typography variant="h3">Danny Boy Links Golf Tours</Typography>
                    <Typography variant="body1">New Jamstack site designed and developed with React and Netlify CMS.</Typography>
                              
                    <Chip color="primary" size="small" label="Jamstack" style={{marginRight:"5px", fontSize:"0.7rem"}}/>
                    <Chip color="primary" size="small" label="React" style={{marginRight:"5px", fontSize:"0.7rem"}} />
                    <Chip color="primary" size="small" label="UX/UI Design" style={{marginRight:"5px", fontSize:"0.7rem"}}/>
                    <Chip color="primary" size="small" label="Netlify CMS" style={{marginRight:"5px", fontSize:"0.7rem"}}/>
                 </Box>
                </Box>                
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="mywork-wrapper small-nudge">
                  <div className="default">
                    <StaticImage src="../img/gt-work-hover.jpg" alt="Grass Technology Ltd"/>
                  </div>
                  {/* <div className="hover">
                    <StaticImage src="../img/gt-work.jpg" alt="Grass Technology Ltd"/>
                  </div> */}
                </div>
                <Box className="mywork-example">
                  <Box>
                    <Typography variant="h3">Grass Technology Ltd</Typography>
                    <Typography variant="body1">New functionality and re-skin of existing Wordpress website.</Typography>
                              
                    <Chip color="primary" size="small" label="WordPress" style={{marginRight:"5px", fontSize:"0.7rem"}}/>
                    <Chip color="primary" size="small" label="Custom Theme Development" style={{marginRight:"5px", fontSize:"0.7rem"}} />
                    <Chip color="primary" size="small" label="UX/UI Design" style={{marginRight:"5px", fontSize:"0.7rem"}}/>
                    <Chip color="primary" size="small" label="Back-End Dev" style={{marginRight:"5px", fontSize:"0.7rem"}}/>
                  </Box>
                </Box>                
              </Grid>


            </Grid>
          </Container>
          </ScrollAnimation>
      </section>
    </Element>
    <section className="worked-with">
      <Element name="workedwith-section">
        <ScrollAnimation animateIn="fadeIn">
          
          <Container maxWidth="lg" spacing={12}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h4">Worked with</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3} className="work-logo-container">
                <StaticImage placeholder="blurred" layout="fixed"  height={60} src="../img/qub_logo.png" alt="Queen's University Belfast Logo"  />
              </Grid>
              <Grid item xs={6} sm={6} md={3} className="work-logo-container">
                <StaticImage placeholder="blurred" layout="fixed"  height={70} src="../img/nicpld_logo.png" alt="NICPLD Logo"  />
              </Grid>
              <Grid item xs={6} sm={6} md={3} className="work-logo-container">
                <StaticImage placeholder="blurred" layout="fixed"  height={60} src="../img/uu_logo.png" alt="Ulster University Logo"  />
              </Grid>
              <Grid item xs={6} sm={6} md={3} className="work-logo-container">                    
                <StaticImage placeholder="blurred" layout="fixed"  height={70} src="../img/hscd-logo.png" alt="High Sheriff of County Down Logo"  />
              </Grid>
              <Grid item xs={6} sm={6} md={3} className="work-logo-container">   
                <StaticImage placeholder="blurred" layout="fixed"  height={50} src="../img/ff_logo.png" alt="FarmFLiX Logo"  />
              </Grid>
              <Grid item xs={6} sm={6} md={3} className="work-logo-container">   
                <StaticImage placeholder="blurred" layout="fixed"  height={66} src="../img/dblgt_logo.png" alt="Danny Boy Links Golf Tour Logo"  />
              </Grid>
              <Grid item xs={6} sm={6} md={3} className="work-logo-container">   
                <StaticImage placeholder="blurred" layout="fixed"  height={60} src="../img/gt_logo.png" alt="GrassTech Logo"  />
              </Grid>
              <Grid item xs={6} sm={6} md={3} className="work-logo-container">   
                <StaticImage placeholder="blurred" layout="fixed"  height={50} src="../img/rms_logo.png" alt="RMS Logo"  />
              </Grid>
             

              {/* <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h4">Worked with</Typography>
                <br/><br/>
                <Box style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                  <Box>
                    <StaticImage placeholder="blurred" layout="fixed"  height={60} src="../img/qub_logo.png" alt="Queen's University Belfast Logo"  />
                  </Box>
                  <Box>
                    <StaticImage placeholder="blurred" layout="fixed"  height={70} src="../img/nicpld_logo.png" alt="NICPLD Logo"  />
                  </Box>
                  <Box>
                    <StaticImage placeholder="blurred" layout="fixed"  height={60} src="../img/uu_logo.png" alt="Ulster University Logo"  />
                  </Box>
                  <Box>
                    <StaticImage placeholder="blurred" layout="fixed"  height={70} src="../img/hscd-logo.png" alt="High Sheriff of County Down Logo"  />
                  </Box>
                </Box>
                <br/><br/>
                <Box style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                  <Box>
                    <StaticImage placeholder="blurred" layout="fixed"  height={50} src="../img/ff_logo.png" alt="FarmFLiX Logo"  />
                  </Box>
                  <Box>
                    <StaticImage placeholder="blurred" layout="fixed"  height={66} src="../img/dblgt_logo.png" alt="Danny Boy Links Golf Tour Logo"  />
                  </Box>
                  <Box>
                    <StaticImage placeholder="blurred" layout="fixed"  height={60} src="../img/gt_logo.png" alt="GrassTech Logo"  />
                  </Box>
                  <Box>
                    <StaticImage placeholder="blurred" layout="fixed"  height={50} src="../img/rms_logo.png" alt="RMS Logo"  />
                  </Box>
                </Box>
              </Grid> */}

            </Grid>
          </Container>
        </ScrollAnimation>
      </Element>
    </section>
    </>
  );
};